/* eslint-disable no-console */
const storageKey = "js_fudo_od_st";
const testMode = false; /* DEVNOTE set this true for testing, so it logs tealium info into console */

export function tealiumLog(data: TealiumLog): void {
	if (window.utag) {
		update(data);
	} else {
		const tealiumInterval = window.setInterval(() => {
			if (window.utag) {
				window.clearInterval(tealiumInterval);
				window.clearTimeout(tealiumTimeLimit);
				update(data);
			}
		}, 100);

		const tealiumTimeLimit = window.setTimeout(() => {
			window.clearInterval(tealiumInterval);
			console.error("Could not access Tealium.");
		}, 3000);
	}
}

export function getTealiumDeviceType(): string {
	const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

	if (width < 768) {
		return "mobile";
	}

	if (width < 1024) {
		return "tablet";
	}

	return "desktop";
}

function update(data: TealiumLog): void {
	updateFakeUdo(data);

	if (data.updateRealUdo) {
		updateRealUdo();
	}

	if (data && data.eventName && data.eventParams) {
		updateEvent(data.eventName, data.eventParams);
	}
}

function getFakeUdo(): any {
	const inStorageData = window.sessionStorage.getItem(storageKey);
	return inStorageData ? JSON.parse(inStorageData) : {};
}

function updateFakeUdo(data: TealiumLog): void {
	// DEVNOTE Settimeout is used to get Redux state and window.bookingData
	window.setTimeout(() => {
		let fakeUdoData = getFakeUdo();

		fakeUdoData = { ...fakeUdoData, ...window.utag.data };

		if (data && data.udoParams) {
			fakeUdoData = { ...fakeUdoData, ...data.udoParams };
		}

		if (data && data.eventName && data.eventParams) {
			fakeUdoData = { ...fakeUdoData, ...data.eventParams };
		}

		window.sessionStorage.setItem(storageKey, JSON.stringify(fakeUdoData));

		if (testMode) {
			console.log("TEALIUM TEST udo update in local storage", fakeUdoData);
		}
	}, 0);
}

function updateRealUdo(): void {
	window.utag.data = getFakeUdo();
	if (testMode) {
		console.log("TEALIUM TEST udo update submitted", window.utag.data);
	}
}

function updateEvent<T>(eventName: string, eventParams: T): {} {
	try {
		const newParams = {
			tealium_event: eventName,
		} as unknown as T;

		(Object.keys(eventParams) as (keyof T)[])
			.filter(
				(key) =>
					eventParams[key] ||
					(eventParams[key] as unknown as boolean) === false ||
					(eventParams[key] as unknown as number) === 0
			)
			.forEach((key) => (newParams[key] = eventParams[key]));
		window.utag.link(newParams);

		if (testMode) {
			console.log("TEALIUM TEST event triggered: '" + eventName + "'", newParams);
		}

		return newParams;
	} catch (e) {
		console.log("Could not fire Tealium event.", eventName, eventParams);
		return {};
	}
}

export interface TealiumLog {
	eventName?: string;
	eventParams?: any;
	udoParams?: any;
	updateCommonUdo?: boolean;
	updateRealUdo?: boolean;
}
