import * as globals from "./Globals";
import i18next from "i18next";
import enUSPo from "../../../../locale/translated/en-us.client.json";
import esCLPo from "../../../../locale/translated/es-cl.client.json";

export interface LocalizationEmbeddedLink {
    url: string;
    title: string;
}

void i18next.init({
    initImmediate: false,
    nsSeparator: false,
    keySeparator: false,
    lng: globals.langKey,
    fallbackLng: "escl",
    resources: {
        // language: { namespace: resourse }
        enus: { translation: enUSPo },
        escl: { translation: esCLPo },
    },
    interpolation: {
        format: (value: any, format?: string) => {
            if (format === "custom") {
                if (value as LocalizationEmbeddedLink) {
                    const linkObject = value as LocalizationEmbeddedLink;
                    return `<a target="blank" href="${linkObject.url}">${linkObject.title}</a>`;
                }
            }
            return value;
        },
    },
});
