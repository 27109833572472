interface TestIdOptions {
	c?: string; // code
	i?: number; // index
	j?: number; // journeyIndex
	p?: number; // paxIndex
	m?: boolean; // isMobile
}

const nameSeparator = "--";
const separator = "-";
const mobilePrefix = "m|";
const journeyIndexPrefix = "j|";
const paxIndexPrefix = "p|";
const indexPrefix = "i|";
const codePrefix = "c|";

export function getTestId(name: string, options: TestIdOptions): string {
	let retVal = "";
	retVal += options.j !== undefined ? `${retVal ? separator : ""}${journeyIndexPrefix}${options.j}` : "";
	retVal += options.p !== undefined ? `${retVal ? separator : ""}${paxIndexPrefix}${options.p}` : "";
	retVal += options.c !== undefined ? `${retVal ? separator : ""}${codePrefix}${options.c}` : "";
	retVal += options.i !== undefined ? `${retVal ? separator : ""}${indexPrefix}${options.i}` : "";
	retVal += options.m === true ? `${retVal ? separator : ""}${mobilePrefix}1` : "";

	return [name, retVal].filter((i) => i).join(nameSeparator);
}

export function decodeTestId(id: string) {
	const [name, rest] = id.split(nameSeparator);
	const args = rest.split(separator);

	const journeyIndex = args.find((a) => a.startsWith(journeyIndexPrefix))?.substring(journeyIndexPrefix.length);
	const paxIndex = args.find((a) => a.startsWith(paxIndexPrefix))?.substring(paxIndexPrefix.length);
	const code = args.find((a) => a.startsWith(codePrefix))?.substring(codePrefix.length);
	const index = args.find((a) => a.startsWith(indexPrefix))?.substring(indexPrefix.length);
	const isMobile = args.some((a) => a.startsWith(mobilePrefix));

	return { name, journeyIndex, paxIndex, code, index, isMobile };
}
