export const TestIdDictionary = {
	SearchboxContainer: "SEARCHBOX_CONTAINER",
	SubmitSearchButton: "SUBMIT_SEARCH_BUTTON",
	AdminModal: {
		Pnr: "ADMIN_MODAL_PNR",
		SubmitButton: "ADMIN_MODAL_SUBMIT_BUTTON",
		UserName: "ADMIN_MODAL_USER_NAME",
	},
	Route: {
		OriginInput: "ROUTE_ORIGIN_INPUT",
		DestinationInput: "ROUTE_DESTINATION_INPUT",
		CountryList: "ROUTE_COUNTRY_LIST",
		CountryListItem: "ROUTE_COUNTRY_LIST_ITEM",
		CityList: "ROUTE_CITY_LIST",
		CityListItem: "ROUTE_CITY_LIST_ITEM",
	},
	Date: {
		DepartureInput: "DATE_DEPARTURE_INPUT",
		ReturnInput: "DATE_RETURN_INPUT",
		MonthContainer: "DATE_MONTH_CONTAINER",
		MonthName: "DATE_MONTH_NAME",
		MoveForward: "DATE_MOVE_FORWARD",
		MoveBack: "DATE_MOVE_BACK",
		Date: "DATE_DATE",
		OneWaySelector: "DATE_ONE_WAY_SELECTOR",
	},
	Pax: {
		Input: "PAX_INPUT",
		AdultAmount: "PAX_ADULT_AMOUNT",
		TeenAmount: "PAX_TEEN_AMOUNT",
		ChildAmount: "PAX_CHILD_AMOUNT",
		InfantAmount: "PAX_INFANT_AMOUNT",
		AddAdult: "PAX_ADD_ADULT",
		RemoveAdult: "PAX_REMOVE_ADULT",
		AddTeen: "PAX_ADD_TEEN",
		RemoveTeen: "PAX_REMOVE_TEEN",
		AddChild: "PAX_ADD_CHILD",
		RemoveChild: "PAX_REMOVE_CHILD",
		AddInfant: "PAX_ADD_INFANT",
		RemoveInfant: "PAX_REMOVE_INFANT",
		CloseButton: "PAX_CLOSE_BUTTON",
		Container: "PAX_CONTAINER",
	},
	Menu: {
		CheckinOpener: "MENU_CHECKIN_OPENER",
		LoginOpener: "MENU_LOGIN_OPENER",
		LogoutOpener: "MENU_LOGOUT_OPENER",
		PeruCompraLink: "MENU_PERU_COMPRA_LINK",
        OpenAmericanAccountButton: "MENU_OPEN_AMERICAN_ACCOUNT_BUTTON",
        LogOutButton: "MENU_LOG_OUT_BUTTON",
	},
};
