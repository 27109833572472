export interface AmplitudeExposureRequestItem {
	ExperimentId: string;
	Variant: string;
}

export async function sendAmplitudeExposure(items: AmplitudeExposureRequestItem[]): Promise<Response> {
	try {
		const baseUrl = "/ABTest/expose";
		const fetchOptions: RequestInit = {
			method: "POST",
			headers: {
				"X-Requested-With": "XMLHttpRequest",
				"Content-Type": "application/json",
			},
			credentials: "same-origin",
			mode: "cors",
			body: JSON.stringify(items),
		};

		const fetchParameters = {
			options: fetchOptions,
			url: baseUrl,
		};

		return fetch(fetchParameters.url, fetchParameters.options);
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error("Could not send exposure to Amplitude.", items);
		return Promise.reject();
	}
}