import {
	ARGENTINIAN_PESO_CODE,
	BRASIL_REAL_CODE,
	BRASIL_REAL_CURRENCY_SIGN,
	GENERAL_CURRENCY_SIGN,
	PERUVIAN_CULTURE_CODE,
	PERUVIAN_SOL_CODE,
	PERUVIAN_SOL_CURRENCY_SIGN,
	USA_CULTURE_CODE,
	USA_DOLLAR_CODE,
} from "./constants";
export default function numberFormatter(data: {
	amount: number;
	culture: string;
	currency: string;
	leadingSign?: boolean;
	omitGrouping?: boolean;
	forcedToRemoveDecimals?: boolean;
}): string {
	data.leadingSign = data.leadingSign === undefined ? true : data.leadingSign;
	const currencySymbol = getCurrencySign(data.currency);

	const currenciesWithDecimals = [USA_DOLLAR_CODE, BRASIL_REAL_CODE, ARGENTINIAN_PESO_CODE, PERUVIAN_SOL_CODE];
	const useDecimals =
		!data.forcedToRemoveDecimals && currenciesWithDecimals.indexOf(data.currency.toUpperCase()) > -1;

	const culturesWithDecimalDot = [USA_CULTURE_CODE, PERUVIAN_CULTURE_CODE];
	const useDecimalDot = culturesWithDecimalDot.indexOf(data.culture.toLowerCase()) > -1;

	data.amount = useDecimals ? Math.round(data.amount * 100) / 100 : Math.round(data.amount);
	let str = useDecimals ? data.amount.toFixed(2) : data.amount.toString();
	str = useDecimalDot ? str : str.replace(".", ",");

	str = data.omitGrouping
		? str
		: useDecimalDot
		? str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
		: str.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

	return data.leadingSign ? `${currencySymbol} ${str}` : str;
}

export function getCurrencySign(currencyCode: string): string {
	return currencyCode.toUpperCase() === BRASIL_REAL_CODE
		? BRASIL_REAL_CURRENCY_SIGN
		: currencyCode.toUpperCase() === PERUVIAN_SOL_CODE
		? PERUVIAN_SOL_CURRENCY_SIGN
		: GENERAL_CURRENCY_SIGN;
}
