import Debug from "debug";

import languagesJson from "../../../../JetSmart.Web/languages.json";

interface Language {
	locale: string;
	country: string;
	lang: string;
	name: string;
}

export const languages: { [key: string]: Language } = Object.assign(
	{},
	...languagesJson.Languages.Items.map((item: any) => {
		const key = item.key;
		delete item.key;
		return { [key]: item };
	})
);

const pathNameItems = window.location.pathname.split("/");
const countryFromPath = pathNameItems[1].toLowerCase();
const langFromPath = pathNameItems[2] ? pathNameItems[2].toLowerCase() : "es";
export const lang = langFromPath + "-" + countryFromPath;
export const langKey = lang.replace("-", "");
export const langPath = countryFromPath + "/" + langFromPath;
export const language = languages[langKey];
export const locale = language ? language.locale : languages.escl.locale;

export const debugimpl = Debug;
