export class JetSmartEvent {
    public static DestinationStationClick = "DestinationStationClick";
    public static DepartureDateSelected = "DepartureDateSelected";
    public static OriginStationClick = "OriginStationClick";
    public static ReturnDateSelected = "ReturnDateSelected";
    public static OneWayTripSelected = "OneWayTripSelected";
    public static RoundTripSelected = "RoundTripSelected";
    public static PassengersChanged = "PassengersChanged";
    public static PromoCodeAdded = "PromoCodeAdded";
    public static SearchBoxTabClick = "SearchBoxTabClick";

    public name!: string;
    public params: any;
}
